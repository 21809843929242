//blank page functional component
import React, { useEffect } from "react";
import TopPageImage from "../../components/TopPageImage";
import ShowBanner from "../../components/ShowBanner";
import GoogleMap from "../../components/GoogleMap";
import Address from "../../components/Address";
import Footer from "../../components/Footer";
const ContactPage = () => {
  useEffect(() => {
    const scrollToTop = () => {
      const scrollOptions = {
        top: 0,
        behavior: "smooth",
        duration: 1000, // 1 second
      };
      window.scrollTo(scrollOptions);
    };

    scrollToTop();
  }, []);
  return (
    <div>
      Contact Page
      <ShowBanner
        title="Contact Us"
        backgroundImage={"/images/warehouse.webp"}
      />
      <Address
        address="17621 S. Susana Rd. Rancho Dominguez, CA 90221"
        phone="(424) 312-1468"
      />
      <GoogleMap />
      <Footer />
    </div>
  );
};

export default ContactPage;
