import React from "react";
import "./styles.css";
import { Container, Row, Col, Image } from "react-bootstrap";

const StaticBackground = () => {
  return (
    <div className="static-background">
      <div className="background-content">
        <Container>
          <div className=" text-center row">
            <div className="col-sm-12 pt-5">
              <Image
                src="https://www.belcastro.group/images/logos/LOGO%20-%20CB%20Logistics%20-%20square%20-%20360x360.webp"
                rounded
                style={{
                  width: "50%",
                  maxWidth: 150,
                  border: "thin solid white",
                }}
              />
              <h1>Why CB Logistics? </h1>
              <p>
              CB Logistics offers comprehensive logistics and supply chain solutions, catering to a variety of domestic needs. Each solution is custom-built based on the unique requirements and goals of our customers. Leveraging our state-of-the-art logistics software, we provide a wide range of supplementary services, ensuring flexibility in mode, time constraints, and cost considerations.
              </p>

              {/* <p>Storage and Management of Materials</p>
              <p>Project Visibility (Virtual Warehouse - 3PL)</p>
              <p>Material Transportation and Handling</p> */}
            </div>
            <div className="customColumn   col-sm-12 d-none">
              <div style={{ width: "100%" }}>
                <Image
                  src="https://www.belcastro.group/images/logos/LOGO%20-%20CB%20Logistics%20-%20square%20-%20360x360.webp"
                  rounded
                  style={{
                    height: "300px",
                    width: "auto",
                    border: "thin solid white",
                  }}
                />
                {/* <Image
                  src="https://i.ibb.co/S30FmBc/HOMEPAGE-CB-Logistics-Warehouse-Munchen-720x1440-1x2.webp"
                  rounded
                  style={{ width: "100%", height: "500px" }}
                /> */}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default StaticBackground;
