import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { useInventoryContext } from "../../context/InventoryContext";
import { useNavigate } from "react-router-dom"; // Corrected import

const LoginFailureMessage = ({ message, onClose }) => {
  if (!message) {
    return null;
  }

  return (
    <div>
      {message && message.length ? (
        <div
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          {" "}
          <strong>Login failed.</strong> {message}
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={onClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      ) : null}
    </div>
  );
};

function LoginPage() {
  const navigate = useNavigate(); // Initialize useNavigate hook

  const { login, loginError, user, setLoginError } = useInventoryContext();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    // Here, you can call your API to login the user with email and password

    console.log("Email:", email, "Password:", password);
    login(email, password);
  };
  // Use useEffect to redirect when the 'user' becomes available
  useEffect(() => {
    if (user) {
      navigate("/inventory"); // Redirect to /inventory page
    }
  }, [user, navigate]); // Re-run this effect when 'user' value changes
  const onClose = () => setLoginError(null); // Clear the error message
  return (
    <Container>
      <div style={{ height: "100px", width: "100%" }}></div>
      <div
        style={{
          width: "60%",
          maxWidth: "200px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <img
          style={{ width: "100%", borderRadius: "10%" }}
          src="https://www.belcastro.group/images/logos/LOGO%20-%20CB%20Logistics%20-%20square%20-%20360x360.webp"
          alt="CB Logistics"
        />
      </div>
      {user ? <h1>Welcome, {user.token}</h1> : null}
      {loginError ? (
        <LoginFailureMessage message={loginError} onClose={onClose} />
      ) : null}
      <Row className="justify-content-md-center mt-5">
        <Col xs={12} md={6}>
          <h2 className="text-center mb-4">Login</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Button
                className="mt-3 bg-cb-blue border"
                svariant="primary"
                type="submit"
              >
                Login
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default LoginPage;
