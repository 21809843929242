import React from "react";
import axios from "axios";
import "./styles.css";
import { formatDate } from "../../lib/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReceipt } from "@fortawesome/free-solid-svg-icons";

const InventoryTable = ({ items, addToCart }) => {
  const handleDownloadReceipt = async (receiverId) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.get(`${process.env.REACT_APP_ROOT_URL}/api/customer/download-receipt/${receiverId}`, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error downloading receipt:", error);
    }
  };

  return (
    <div className="catalog-table">
      {items ? (
        <table className="table table-striped table-inventory">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Description</th>
              <th scope="col">ITEM #</th>
              <th scope="col">Reference #</th>
              <th scope="col">PO #</th>
              <th scope="col">Received</th>
              <th scope="col">Avail.</th>
              <th scope="col">Received Date</th>
              <th scope="col">Internal Ref ID</th>
              <th scope="col">Action</th>
              <th scope="col">View Receipt</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => {
              return (
                <tr key={item.receiveItemId} style={{ verticalAlign: "middle" }}>
                  <td style={{ verticalAlign: "middle" }}>{item.description}</td>
                  <td style={{ verticalAlign: "middle" }}>{item.itemIdentifier.sku}</td>
                  <td style={{ verticalAlign: "middle" }}>{item.referenceNum}</td>
                  <td style={{ verticalAlign: "middle" }}>{item.poNum}</td>
                  <td style={{ verticalAlign: "middle" }}>{item.received}</td>
                  <td style={{ verticalAlign: "middle" }}>{item.available}</td>
                  <td style={{ verticalAlign: "middle" }}>{formatDate(item.receivedDate)}</td>
                  <td style={{ verticalAlign: "middle" }}>{item.receiveItemId}</td>
                  <td style={{ verticalAlign: "middle" }}>
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => addToCart(item)}
                    >
                      Add to Cart
                    </button>
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => handleDownloadReceipt(item.receiveItemId)}
                    >
                      <FontAwesomeIcon icon={faReceipt} /> Receipt
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        "No results found."
      )}
    </div>
  );
};

export default InventoryTable;