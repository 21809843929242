import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";

import About from "../pages/About";
import ContactPage from "../pages/Contact";
import ComingSoon from "../pages/ComingSoon";
import CaseStudies from "../pages/CaseStudies";
import SingleCaseStudy from "../pages/SingleCaseStudy";
import Services from "../pages/Services";
import Dvbe from "../pages/Dvbe";
import BelcastroApp from "../pages/BelcastroApp";
import { InventoryProvider } from "../context/InventoryContext";
import PrivacyPolicy from "../pages/Privacy/index";
import ResetPasswordForm from "../pages/UserResetPassword";
import Cart from "../pages/Cart";
import LoginPage from "../pages/Login";
import Header from "../components/Header";
import ServicesV2 from "../pages/ServicesV2";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
const RoutesMain = () => {
  
  return (
    <Router>
      
      {/* Move InventoryProvider here */}
      <Header />
   
      <Routes>
        
        <Route exact path="/" element={<Home />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services/:service" element={<ServicesV2 />} key="services-service"/>
        <Route path="/dvbe" element={<Dvbe />} />
        <Route path="/case-studies" element={<CaseStudies />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/users/reset-password" element={<ResetPasswordForm />} />
        <Route path="/login" element={<LoginPage />} />
        {/* Remove InventoryProvider */}
        <Route path="/inventory" element={<BelcastroApp />} />
        {/* Remove InventoryProvider */}
        <Route
          path="/case-studies/:caseStudyName"
          element={<SingleCaseStudy />}
        />
        <Route path="/*" element={<ComingSoon />} />
      </Routes>
    </Router>
  );
};

export default RoutesMain;
