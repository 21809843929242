import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import { Switch, Route, Link } from "react-router-dom";
import "./styles.css";

const Footer = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setShowDropdown(screenWidth <= 768); // Set showDropdown based on screen width (e.g., 768px for mobile)
    };

    handleResize(); // Set initial value on component mount

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col xs={12} md={6} lg={3} className="footer-column">
            <h4 className="footer-title" style={{ margin: 0, padding: 0 }}>
              CB Logistics
            </h4>
          </Col>
        </Row>
        <Row className="footer-row ">
          <Col xs={12} md={6} lg={3} className="footer-column">
            {showDropdown ? (
              <div>
                <p className="footer-text">
                  <Link to="/">Home</Link>
                </p>
                <div
                  className="footer-dropdown-button footer-text"
                  onClick={handleDropdownToggle}
                  role="button"
                  style={{ fontSize: "1rem", fontWeight: "bold" }}
                >
                  Show More
                </div>
              </div>
            ) : null}
          </Col>
          {/* Remaining columns */}
        </Row>
        {!showDropdown ? (
          <Row className="footer-row">
            <Col xs={12} md={6} lg={3} className="footer-column ">
              <p className="footer-text">
                <Link to="/">Home</Link>
              </p>
              <p className="footer-text">
                <Link to="/contact">Contact</Link>
              </p>
            </Col>
            <Col xs={12} md={6} lg={3} className="footer-column">
              <p className="footer-text">
                {" "}
                <Link to="/about">About</Link>
              </p>
              <p className="footer-text">
                {" "}
                <Link to="/case-studies">Case Studies</Link>
              </p>
            </Col>
            <Col xs={12} md={6} lg={3} className="footer-column">
              <p className="footer-text">
                <Link to="/facilities">Facilities</Link>
              </p>
              <p className="footer-text">
                {" "}
                <Link to="https://belcastrogroup.blog/">Blog</Link>
              </p>
            </Col>
            <Col xs={12} md={6} lg={3} className="footer-column">
              <p className="footer-text">
                <Link to="/dvbe">D.V.B.E.</Link>
              </p>
              <p className="footer-text">
                <Link to="/">Clients</Link>
              </p>
            </Col>
          </Row>
        ) : null}
        <Row className="footer-row">
          <Col className="social-icons">
            <Link to="https://www.facebook.com/belcastroConstruction">
              <FaFacebook className="social-icon" />
            </Link>
            <Link to="https://twitter.com/BelcastroGroup1">
              <FaTwitter className="social-icon" />
            </Link>
            {/* <FaInstagram className="social-icon" /> */}
            <Link to="https://www.linkedin.com/in/peter-belcastro-770b5526/">
              <FaLinkedin className="social-icon" />
            </Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
