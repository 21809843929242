import React, { useEffect } from 'react'
import FullWidthBanner from '../../components/FullWidthBanner'
import { useParams } from 'react-router-dom';
import Footer from '../../components/Footer'
import './styles.css'
import {data} from './data'
import { useLocation } from 'react-router-dom';
 const useScrollToTop = () => {
    const location = useLocation();
  
    useEffect(() => {
      if (location.pathname) {
        window.scrollTo(0, 0);
      }
    }, [location]);
  };
const ServicesV2 = () => {
   
    
    const { service } = useParams();
    useScrollToTop();
    return (
        <div style={{marginTop:'70px'}}>
            
            {data[service]?
            <React.Fragment>
                <FullWidthBanner title={data[service]?.title} img={data[service]?.img}/>
                <div className="row">
                    {data[service]?.paragraphs.map((item, index) => (
                    <React.Fragment key={index}>
                    {/* <div style={{ display: 'flex', alignItems: 'center' }}> Inline styles for flexbox */}
                      <div className="col-md-4 col-sm-12" style={{ display: 'flex', alignItems: 'center' }}>
                        <h1 className="service-title">{item.title}</h1>
                      </div>
                      <div className="col-md-8 col-sm-12" style={{ display: 'flex', alignItems: 'center' }}>
                        <p>{item.description}</p>
                      </div>
                    {/* </div> */}
                  </React.Fragment>
                    ))}
                </div>
                <div className="row mt-5">
                    <div className="col-md-6">
                        {data[service] && data[service].listImages && data[service]?.listImages.map(item=><img src={item} alt="Construction Logistics" className="img-fluid my-5" style={{maxWidth:'75%', width:'400px', height:'400px'}}/>)}
                    </div>
                    <div className="col-md-6 px-5">
                        <h1>{data[service]?.listTitle}</h1>
                        <ul>
                            {data[service]  && data[service].list &&  data[service]?.list.map((item, index) => (
                                <li key={index} style={{listStyleType: '', textAlign:'start'}}>
                                    <h3 style={{margin:'6px', padding:0}}>{item.title}</h3>
                                    <p className="my-0 py-0">{item.text}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </React.Fragment>:<h1>Coming Soon...</h1>}
            <Footer />
        </div>
    )
}

export default ServicesV2


