import React from "react";
import { useInventoryContext } from "../../context/InventoryContext";

import AppCustomerInfo from "../AppCustomerInfo";
import AppInventory from "../AppInventory";
function InventoryManagement() {
  const {
    inventory,
    orders,
    customerId,
    updateInventory,
    updateOrders,
    updateCustomerId,
  } = useInventoryContext();

  // Example function to update inventory
  const handleUpdateInventory = () => {
    const newInventory = [...inventory]; // Make a copy of the inventory array
    // Update newInventory array as needed
    updateInventory(newInventory); // Update context state
  };

  // Example function to update orders
  const handleUpdateOrders = () => {
    const newOrders = [...orders]; // Make a copy of the orders array
    // Update newOrders array as needed
    updateOrders(newOrders); // Update context state
  };

  // Example function to update customer ID
  const handleUpdateCustomerId = () => {
    const newCustomerId = 2; // Replace with actual customer ID
    updateCustomerId(newCustomerId); // Update context state
  };

  return (
    <div>
      <h2>Inventory Management</h2>
      <div style={{ width: "100%", height: "50px" }}> </div>
      <AppCustomerInfo />
      <AppInventory />
    </div>
  );
}

export default InventoryManagement;
