import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy" style={{ marginTop: 100 }}>
      <h1>Privacy Policy</h1>

      <h2>1. Introduction</h2>
      <p>
        Welcome to Belcastro, Inc. We respect your privacy and want to protect
        your personal information. This Privacy Policy explains how we collect,
        use, and disclose your personal information.
      </p>

      <h2>2. Information Collection</h2>
      <p>We may collect the following information:</p>
      <ul style={{ listStylePosition: "inside", paddingLeft: "0px" }}>
        <li>Name and job title</li>
        <li>Contact information including email address</li>
        <li>
          Demographic information such as postcode, preferences, and interests
        </li>
        <li>Other information relevant to customer surveys and/or offers</li>
      </ul>

      <h2>3. How We Use Information</h2>
      <p>We use the information we collect for the following purposes:</p>
      <ul style={{ listStylePosition: "inside", paddingLeft: "0px" }}>
        <li>To provide our services</li>
        <li>To improve our services</li>
        <li>
          To send periodic emails about new products, special offers, or other
          information which we think you may find interesting
        </li>
      </ul>

      <h2>4. Security</h2>
      <p>
        We are committed to ensuring that your information is secure. We have
        implemented suitable physical, electronic, and managerial procedures to
        safeguard and secure the information we collect online.
      </p>

      <h2>5. How We Use Cookies</h2>
      <p>
        A cookie is a small file which asks permission to be placed on your
        computer's hard drive. We use cookies to analyze web traffic and improve
        our website. We only use this information for statistical analysis
        purposes.
      </p>

      <h2>6. Links to Other Websites</h2>
      <p>
        Our website may contain links to other websites. However, once you have
        used these links to leave our site, we do not have any control over that
        other website. Therefore, we cannot be responsible for the protection
        and privacy of any information which you provide while visiting such
        sites.
      </p>

      <h2>7. Your Choices</h2>
      <p>
        You may choose to restrict the collection or use of your personal
        information in the following ways:
      </p>
      <ul style={{ listStylePosition: "inside", paddingLeft: "0px" }}>
        <li>
          Whenever you are asked to fill in a form on the website, look for the
          box that you can click to indicate that you do not want the
          information to be used for direct marketing purposes
        </li>
        <li>
          If you have previously agreed to us using your personal information
          for direct marketing purposes, you may change your mind at any time by
          contacting us
        </li>
      </ul>

      <h2>8. Contacting Us</h2>
      <p>
        If you have any questions about this Privacy Policy, the practices of
        this site, or your dealings with this site, please contact us at{" "}
        <a href="mailto:belcastrosocials@gmail.com">
          belcastrosocials@gmail.com
        </a>
        .
      </p>
    </div>
  );
};

export default PrivacyPolicy;
