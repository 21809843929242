import React, { useState, useEffect } from 'react';
import './styles.css'; // Ensure this path matches the location of your CSS file

function ShippingAddressForm({ onSave, handleShippingAddressChange }) {
  const [formData, setFormData] = useState({
    companyName: '',
    name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    
  };
  useEffect(() => {
    handleShippingAddressChange(formData);
  }, [formData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="shipping-form-container">
      <h3>Shipping Cargo To:</h3>
      <div className="mb-0">
        <label htmlFor="companyName" className="shipping-form-label">Company Name</label>
        <input type="text" className="shipping-form-input" id="companyName" name="companyName" value={formData.companyName} onChange={handleChange} required />
      </div>
      <div className="mb-0">
        <label htmlFor="name" className="shipping-form-label">Name</label>
        <input type="text" className="shipping-form-input" id="name" name="name" value={formData.name} onChange={handleChange} required />
      </div>
      <div className="mb-0">
        <label htmlFor="address1" className="shipping-form-label">Address 1</label>
        <input type="text" className="shipping-form-input" id="address1" name="address1" value={formData.address1} onChange={handleChange} required />
      </div>
      <div className="mb-0">
        <label htmlFor="address2" className="shipping-form-label">Address 2 (Optional)</label>
        <input type="text" className="shipping-form-input" id="address2" name="address2" value={formData.address2} onChange={handleChange} />
      </div>
      <div className="mb-0">
        <label htmlFor="city" className="shipping-form-label">City</label>
        <input type="text" className="shipping-form-input" id="city" name="city" value={formData.city} onChange={handleChange} required />
      </div>
      <div className="mb-0">
        <label htmlFor="state" className="shipping-form-label">State</label>
        <input type="text" className="shipping-form-input" id="state" name="state" value={formData.state} onChange={handleChange} required />
      </div>
      <div className="mb-0">
        <label htmlFor="postalCode" className="shipping-form-label">Postal Code</label>
        <input type="text" className="shipping-form-input" id="postalCode" name="postalCode" value={formData.postalCode} onChange={handleChange} required />
      </div>
      <div className="mb-0">
        <label htmlFor="country" className="shipping-form-label">Country</label>
        <input type="text" className="shipping-form-input" id="country" name="country" value={formData.country} onChange={handleChange} required />
      </div>
      {/* <button type="submit" className="shipping-form-button btn btn-info mt-2">Save Address</button> */}
    </form>
  );
}

export default ShippingAddressForm;
