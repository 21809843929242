import React, { useEffect } from "react";
import "./styles.css";
import { Image, Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import data from "./data.js";
import TextWithNewLines from "../../components/TextWithNewLines";
import Footer from "../../components/Footer";
const SingleCaseStudy = () => {
  const { caseStudyName } = useParams();
  console.log("caseStudyName", caseStudyName);

  const study = data.find((study) => study.url === caseStudyName);
  console.log("study", study);
  return (
    <div style={{ marginTop: 100 }}>
      <Container>
        <Row>
          <Col>
            <h2>{study.title}</h2>
            <Image src={`/images/${study.images[0]}`} fluid width={"100%"} />
            <Image src={`/images/${study.images[1]}`} fluid width={"100%"} />
            <p>{study.description}</p>
            {/* <Image
              src={`/images/${study.images[1]}`}
              fluid
              style={{ marginRight: 45 }}
            /> */}
            {/* <Image src={`/images/${study.images[2]}`} fluid /> */}
            <div style={{ width: "100%", height: 50 }}></div>

            {study
              ? study.sections.map((section, index) => {
                  return (
                    <div key={index}>
                      <h2>{section.title}</h2>
                      {section.images
                        ? section.images.map((image, index) => {
                            return (
                              <Image
                                src={`/images/${section.images[index]}`}
                                style={{ margin: 40 }}
                                fluid
                              />
                            );
                          })
                        : null}

                      <p>
                        <TextWithNewLines text={section.content} />
                      </p>
                    </div>
                  );
                })
              : null}
          </Col>
        </Row>
        <Row>
          {study.images
            ? study.images.map((image, index) => {
                if (index > 2) {
                  return (
                    <Col>
                      <Image src={`/images/${study.images[index]}`} fluid />
                    </Col>
                  );
                }
              })
            : null}
        </Row>
        <div style={{ width: "100%", height: 100 }}></div>
      </Container>
      <Footer />
    </div>
  );
};

export default SingleCaseStudy;
