//functional react component
import React, { useEffect, useState } from "react";
import "./styles.css";
import BackgroundVideo from "../../components/BackgroundVideo";
import BackgroundStatic from "../../components/BackgroundStatic";
import HoverSquareRow from "../../components/HoverSquareRow";
import Footer from "../../components/Footer";
import RowSplit from "../../components/RowSplit";
import GalleryComponent from "../../components/GalleryComponent";
import OverlayImage from "../../components/OverlayImage";
import { Image } from "react-bootstrap";
import { Element } from "react-scroll";
import AnimatedSection from "../../components/AnimatedSection";
import BenefitsList from "../../components/BenefitsList";
import { useNavigate } from 'react-router-dom';
import ParagraphWithTitle from "../../components/ParagraphWithTitle";
import GalleryServices from "../../components/GalleryServices";
import NavigateWrapper from "../../components/NavigateWrapper";

  



const techSection = () => {

  return (
    <section id="technology">
      <div
        style={{ background: "white", minHeight: 400 }}
        className="col p-5 b-logistics-text"
      >
        <h1>Our Technology</h1>
        <Image src="/images/technology.jpeg" width={400} height={400} />
        <p>
          CB Logistics technology and logistics capabilities allow us to keep up
          with evolving customer demands. We are always at your service with
          real-time information, 24/7 customer support, and 360° visibility of
          inventory.
        </p>
        <p>
          We are developing propreitary software with intutitive user interfaces
          so our customers can quickly see the status of their orders and
          location of their inventory.
        </p>
      </div>
    </section>
  );
};
const Home = () => {
  const title = "Delivering Excellence, Every Mile";//"Intelligent Materials Management";
  const subtitle =
    //"Providing advanced construction logistics to contractors. Get your materials when you need them.";
    "Experience flawless logistics with a multimodal provider offering both ground transportation and warehousing services. Benefit from scalable capacity and competitive pricing per unit, ensuring cost-effectiveness at every stage of your logistics journey.";
  
  const button = "Schedule A Call";
  const buttonTwo = "Contact Us";
  const rows = [
    {
      image: "/images/storage.png",
      title: "Managed Storage",
      paragraph: [
        " We offer flexible storage facilities tailored to your project's unique demands. ",
        // "Our pay-as-you-go model is designed to respond to your ever-changing storage needs. Just pay for what you actually use.",
        // "Our fixed cost model is designed for those lookeing to avoid monthly bills.",
        // "Trust us to handle your storage so you can focus on what you do best – build.",
        <ul style={{ listStyleType: "none" }}>
          <li className="pb-3">
            <i class="fas fa-hand-holding-usd"></i> Pay-As-You-Go – our way of
            being responsive to ever-changing project and storage plans. You
            won’t need to estimate how much space you will need, just pay for
            what you use.
          </li>
          <li>
            <i class="fas fa-calculator"></i> Fixed Cost – this option model
            enables General Contractors to plan and budget efficiently
          </li>
        </ul>,
      ],
      button: "Lets Get Started",
      link: "/contact",
    },
    {
      image: "/images/shipping.png",
      title: "Material Transportation and Delivery",
      paragraph: [
        "Efficient material delivery and handling form the lifeblood of any construction project, and at CB Logistics, we are the experts you can trust. We offer comprehensive services for transporting materials to and from your worksite, providing you with the certainty that your materials will arrive exactly when and where they're needed.",
        "We've integrated advanced third-party logistics (3PL) into our services. This technology allows you to track your materials in real-time, giving you full visibility of your supplies from the moment they leave the warehouse until they arrive at the construction site.",
        // "But we don’t just deliver; we handle your materials with utmost care and professionalism. Our skilled team is trained in best-practice procedures to ensure your materials arrive in pristine condition, ready for immediate use.",
      ],
      button: "Lets Get Started",
      link: "/contact",
    },
    {
      image: "/images/procurement2.png",
      title: "Procurement",
      paragraph: [
        "At CB Logistics, we transform the complex task of procurement of building supplies into a seamless, stress-free experience. ",

        // "Our specialized services streamline every step of the procurement journey, from procuring comprehensive price quotes to submitting meticulous purchase orders, tracking payments, and ensuring fulfillment. Our dedicated team of experts keeps track of the minutiae, allowing you to focus on the bigger picture of your construction project.  ",
        <ul style={{ listStyleType: "none" }}>
          <li>
            <i class="fas fa-file-invoice-dollar"></i> Procuring Comprehensive
            Quotes
          </li>
          <li>
            <i class="fas fa-file-alt"></i> Submitting Meticulous Purchase
            Orders
          </li>
          <li>
            <i class="fas fa-money-check"></i> Tracking Payments
          </li>
          <li>
            <i class="fas fa-clipboard-check"></i> Ensuring Fulfillment
          </li>
        </ul>,
        `Our industry experts are committed to the successful realization of your construction vision and will keep track of the minutiae, allowing you to focus on the big picture of your construction project and delivering results.
        `,
        // "When you choose CB Logistics, you're not just choosing a procurement service—you're choosing a partner committed to the successful realization of your construction vision.",
      ],
      button: "Lets Get Started",
      link: "/contact",
    },
  ];
  const services =
  {
    title: "Tailored Solutions with Specialized Services",
    description: "We are dedicated to delivering tailored, end-to-end solutions that meet our customers' unique requirements. Leveraging decades of experience, we offer a wide range of logistics services, including ground and warehousing, designed to address your specific needs. Our commitment is to build customized solutions that maximize your benefits, providing seamless and efficient logistics support.",
    services:[]
  }
  const clientGallery = {
    title: "Clients",
    description:
      "CB Logistics is trusted by the best.  We are proud to have worked with the following companies:",
    gallery: [
      {
        img: "https://media.datacenterdynamics.com/media/images/logo_349x175.2e16d0ba.fill-279x140.png",
        title: "Rosedin",
        link: "https://www.rosendin.com/",
      },
      {
        img: "https://www.legaleaseplan.com/sites/default/files/mmc-logo-landscape%20transparent.png",
        title: "Morrow Meadows",
        link: "https://www.morrow-meadows.com/",
      },
      {
        img: "https://www.coscofire.com/wp-content/uploads/2018/06/logo.png",
        title: "Cosco Fire Protection",
        link: "https://www.coscofire.com/",
      },

      // {
      //   img: "https://scoutstatics.levelset.com/contractor-logos/5CAFFD3876F42757323200.png",
      //   title: "Comet Electric",
      //   link: "https://www.cometelectric.com/",
      // },
      {
        img: "https://www.aga-ca.com/wp-content/uploads/2021/01/aga-logo-web-notag.png",
        title: "AGA",
        link: "https://www.aga-ca.com/",
      },
      {
        img: "https://www.isecinc.com/wp-content/themes/isec/assets/img/logo-isec.svg",
        title: "ISEC",
        link: "https://www.isecinc.com/",
      },
      {
        img: "https://www.jmsmucker.com/bundles/jmsmucker/img/jms-logo-tagline.svg",
        title: "The J.M. Smucker Company",
        link: "https://www.jmsmucker.com/",
      },

      {
        img: "https://upload.wikimedia.org/wikipedia/en/d/d2/Logo-schindler.png",
        title: "Schindler Elevator Corporation",
        link: "https://www.schindler.com/us/internet/en/home.html",
      },
      {
        img: "https://static.wixstatic.com/media/3058b4_5dd4346b09244ec9a51a3ed6d3574844~mv2.png/v1/fill/w_312,h_290,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/3058b4_5dd4346b09244ec9a51a3ed6d3574844~mv2.png",
        title: "Taft Electric",
        link: "https://www.taftelectric.com/",
      },
      {
        img: "https://baliconstruction.com/wp-content/uploads/2021/09/logo.png",
        title: "Bali Construction",
        link: "https://baliconstruction.com/",
      },
      {
        img: "https://griffithcompany.net/wp-content/uploads/2018/08/gc-logo.png",
        title: "Griffith Company",
        link: "https://griffithcompany.net/",
      },
      {
        img: "https://projectmap.s3-us-west-1.amazonaws.com/companies/logos/bedbebdbbc8ca059271473e877546e6a.png",
        title: "Anning-Johnson Company",
        link: "https://anningjohnson.com/",
      },
    ],
  };

  const dvbe = [
    {
      image: "/images/usa-flag.jpeg",
      title: "DVBE",
      paragraph: [
        // "Complete DVBE requirements with real veterans.",

        `CB Logistics is a Disabled Veteran Business Enterprise (DVBE) and Small Business Enterprise (SBE) that has been providing construction services since 2018.`,
        `Founded by entrepreneur Peter Belcastro, who served in the U.S. Naval Construction Force from 2007 to 2015. 
        `,
        ` We make it simple to fulfill DVBE requirements with our services:
        `,
        <ul style={{ listStyle: "none" }}>
          <li>
            <i class="fas fa-hard-hat"></i> Construction Material Management
          </li>
          <li>
            <i class="fas fa-truck"></i> Logistics
          </li>
          <li>
            <i class="fas fa-tools"></i> Rental Equipment
          </li>
          <li>
            <i class="fas fa-lightbulb"></i> Wholesale Electrical
          </li>
          <li>
            <i class="fas fa-hammer"></i> Construction Management
          </li>
        </ul>,
      ],
      button: "Learn More",
      link: "/contact",
    },
  ];
  const navigate = useNavigate();

  const [videoLoaded, setVideoLoaded] = useState(false);
  useEffect(() => {
    const scrollToTop = () => {
      const scrollOptions = {
        top: 0,
        behavior: "smooth",
        duration: 1000, // 1 second
      };
      window.scrollTo(scrollOptions);
    };

    scrollToTop();
  }, []);

  useEffect(() => {
    const handleVideoLoaded = () => {
      setVideoLoaded(true);
    };

    const videoElement = document.getElementById("background-video");
    videoElement.addEventListener("loadeddata", handleVideoLoaded);

    return () => {
      videoElement.removeEventListener("loadeddata", handleVideoLoaded);
    };
  }, []);

  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [shouldAnimateTwo, setShouldAnimateTwo] = useState(false);
  useEffect(() => {
    console.log("use effect called");
    const handleScroll = () => {
      console.log('scroll called')
      const section = document.getElementById("technology");
      const sectionTop = section.offsetTop;
      const sectionHeight = section.offsetHeight;
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;

      if (scrollPosition > sectionTop - windowHeight + sectionHeight * 0.1) {
        console.log("should animate");
        setShouldAnimate(true);
      } else {
        setShouldAnimate(false);
        console.log("should not animate")
      }

      const sectionTwo = document.getElementById("benefits");
      const sectionTwoTop = sectionTwo.offsetTop;
      const sectionTwoHeight = sectionTwo.offsetHeight;

      if (
        scrollPosition >
        sectionTwoTop - windowHeight + sectionTwoHeight * 0.5
      ) {
        setShouldAnimateTwo(true);
      } else {
        setShouldAnimateTwo(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollToSection = ({sectionToScrollTo}) => {
    if(sectionToScrollTo)
    {
      const section = document.getElementById(sectionToScrollTo);
      section.scrollIntoView({ behavior: "smooth" });
    }
    else  //default
    {
      const section = document.getElementById("anchor-benefits");
      section.scrollIntoView({ behavior: "smooth" });
    }
    
  };
  const goHere = ({slug}) => {
    scrollToSection({sectionToScrollTo:'top'});
 
    //set timeout
    setTimeout(() => {
    if(slug)
    {
      navigate(slug);
    }else //default
    {
    navigate('/contact');
    }
  },800);
  };
  return (
    <div>
      {!videoLoaded && (
        <div className="loading-overlay">
          <img
            src="/images/loading.gif"
            alt="Loading"
            className="loading-gif"
          />
        </div>
      )}
      <div id="top"></div>
      <BackgroundVideo
        title={title}
        subtitle={subtitle}
        button={button}
        buttonTwo={buttonTwo}
        onVideoLoaded={() => setVideoLoaded(true)}
        buttonAction={()=>goHere('/contact')}
        // navigate to contact section
        buttonActionTwo={()=>goHere('/contact')}
      />
      {/* rest of the code */}
      {videoLoaded && (
        <React.Fragment>
          <BackgroundStatic />
          {/* <ParallaxComponent /> */}
          {/* space for the background component to show */}
          
          <div style={{ height: "800px", width: "100%" }}></div>
          <div
            id="anchor-services"
           
            >
          {/* <ParagraphWithTitle title={services.title} description={services.description} background={"#fff"} /> */}
          <GalleryServices services={services.services} goHere={goHere}/>
          </div>
          {/* <div
            id="anchor-benefits"
            style={{ minHeight: 10, background: "transparent" }}
          ></div> */}
          {/* <section
            id="benefits"
            style={{ background: "white",  }}
            className="mt-5"
          > */}
            {/* <div
              // className="col p-5 b-logistics-text"
              className={` ${
                shouldAnimateTwo ? "animate" : ""
              } pb-5 contractors`}
            >
              <h1>Contractors</h1>

              <p>
                As highly trained logistics and construction experts, we are
                dedicated to ensuring effective material management and
                first-class customer service.
              </p>

              <div
                className={`target-section ${
                  shouldAnimateTwo ? "animate" : ""
                }`}
              >
                <BenefitsList />
              </div>
            </div> */}
          {/* </section> */}

          {/* <div style={{ minHeight: 50, background: "white" }}></div> */}
          {/* <div
            style={{ minHeight: 500, background: "white" }}
            className="d-none d-md-block"
          >
            <HoverSquareRow background={"#002a6a"} />
          </div> */}

          <div className="py-5" style={{ background: "white" }}>
            <GalleryComponent {...clientGallery} showCompanyTitles={false} />
          </div>

          {false && rows.map((item, index) => (
            <RowSplit
              background={"#002a6a"}
              color={"white"}
              image={item.image}
              title={item.title}
              paragraph={item.paragraph}
              buttonLabel={item.button}
              link={item.link}
              titleSize="2rem"
              textSize={"1rem"}
              buttonText="Learn More"
              imageOnLeft={index % 2 === 0} // or imageOnLeft={false} for image on the right
            />
          ))}
          <div style={{ height: 600, width: "100%" }}></div>
        </React.Fragment>
      )}
      {/* <AnimatedSection name="technology" shouldAnimate={shouldAnimate}> */}
      <section id="technology">
        <div
          style={{ background: "white", minHeight: 400,  }}
          className="col p-5 b-logistics-text"
        >
          <div className={`target-section-todo ${shouldAnimate ? "animate" : ""}`}>
            <h1>Our Technology</h1>

            <Image src="/images/technology.png" width={"100%"} />
            <p>
              CB Logistics technology and logistics capabilities allow us to
              keep up with evolving customer demands. We are always at your
              service with real-time information, 24/7 customer support, and
              360° visibility of inventory.
            </p>
            <p>
              We are developing propreitary software with intutitive user
              interfaces so our customers can quickly see the status of their
              orders and location of their inventory.
            </p>
          </div>
        </div>
      </section>
      {/* </AnimatedSection> */}
      {dvbe.map((item, index) => (
        <RowSplit
          background={"#002a6a"}
          color={"white"}
          image={item.image}
          title={item.title}
          paragraph={item.paragraph}
          buttonLabel={item.button}
          link={item.link}
          titleSize="2rem"
          textSize={"1rem"}
          buttonText="Learn More"
          imageOnLeft={index % 2 === 0} // or imageOnLeft={false} for image on the right
        />
      ))}
      {/*  */}
      <Footer />
    </div>
  );
};
export default Home;
