import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import "./styles.css"; // Import the CSS file for styling
import { Link } from "react-router-dom";

const RowSplit = ({
  color,
  background,
  image,
  title,
  paragraph,
  buttonText,
  imageOnLeft,
  link,
}) => {
  const rowClass = imageOnLeft
    ? "row-split image-left"
    : "row-split image-right";

  return (
    <div
      className={rowClass}
      style={{
        background: background ? background : "white",
        color: color ? color : "black",
        margin: 0,
      }}
    >
      <div className="row my-5 align-items-center" style={{ margin: 0 }}>
        {imageOnLeft ? (
          <div className="col-md-6">
            <div className="image-container">
              <img src={image} alt="Image" />
            </div>
          </div>
        ) : null}
        <div className="col-md-6">
          <div className="content-container">
            <h2>{title}</h2>
            {paragraph.map((p, index) => (
              <p key={index}>{p}</p>
            ))}
            {buttonText && (
              <Link to={`${link}`} className="my-5">
                <Button
                  color="primary"
                  size="lg"
                  style={{ fontSize: "2rem" }}
                  className="my-5"
                >
                  {buttonText}
                </Button>
              </Link>
            )}
          </div>
        </div>
        {!imageOnLeft ? (
          <div className="col-md-6">
            <div className="image-container">
              <img src={image} alt="Image" />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

RowSplit.propTypes = {
  background: PropTypes.string,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.arrayOf(PropTypes.string).isRequired,
  buttonText: PropTypes.string,
  imageOnLeft: PropTypes.bool,
};

export default RowSplit;
