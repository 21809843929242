const cartItem =
{
dateAdded
: 
"2023-12-16T05:15:43.111Z",
description
: 
"ASSA ABLOY Door Group",
receiveItemId
: 
"28519",
sku
: 
"3-4-CAR050.a",
userEmail
: 
""
}

export const formatAPIisExpecting =
 {
    "customerIdentifier": {
        "id": "2"   //get this through token in api ? 
    },
    "facilityIdentifier": {
        "id": "1"           //can make this constant for now 
    },
    "referenceNum": "TEST2",   //?
    "notes": "Warehouse Instructions",  
    "shippingNotes": "none",
    "billingCode": "unknown",  //?
    "routingInfo": {
        "carrier": "UPS",
        "mode": "92",
        "scacCode": "UPGN",
        "account": "12345z"
    },
    "shipTo": {                           //probably need to get this from the cart
        "companyName": "3PLCentral",
        "name": "test",
        "address1": "222 N PCH HWY",
        "address2": "Suite 1500",
        "city": "EL Segundo",
        "state": "CA",
        "zip": "90245",
        "country": "US"
    },
    "orderItems": [
        {
            "itemIdentifier": {
                "sku": "Example"  //from the post body
            },
            "qty": 1 , //from the post body
            receiveItemId:"number" //from the post body

        }
    ]
}