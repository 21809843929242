import React, { useEffect, useState } from "react";
import { useInventoryContext } from "../../context/InventoryContext";
import { FaListUl, FaTable } from 'react-icons/fa';
import AppCustomerInfo from "../AppCustomerInfo";
import { Blocks } from "react-loader-spinner";
import InventoryList from "../../components/InventoryList";
import InventoryTable from "../../components/InventoryTable"; // Create this component for the table view

const searchBoxStyles = {
  fontSize: "1.5rem",
  padding: "15px 20px",
  borderWidth: "3px", // Adjust this value for the desired border thickness
};

const SearchThis = ({ filterInventory }) => {
  return (
    <div className="container mb-2">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <input
            style={searchBoxStyles}
            type="text"
            className="form-control form-control-lg"
            placeholder="Search SKU or Description..."
            onChange={(e) => filterInventory({ searchTerm: e.target.value })}
          />
        </div>
      </div>
    </div>
  );
};

function InventoryManagement(props) {
  const {
    cartCount,
    cartItems,
    handleAddToCart,
    getInventory,
    filterInventory,
    filteredList,
    inventory,
    inventoryList,
    orders,
    customerId,
    updateInventory,
    updateOrders,
    updateCustomerId,
    user,
   
  } = useInventoryContext();

  const [page, setPage] = useState(1);
  const [items, setItems] = useState(null);
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState('table'); // State to toggle between list and table view

  useEffect(() => {
    // Make the API call to get the list of items
    getInventory({ id: customerId, page });
  }, [customerId]);

  const toggleView = () => {
    setView(view === 'list' ? 'table' : 'list');
  };

  return (
    <div className="mt-3">
      {filterInventory ? (
        <SearchThis filterInventory={filterInventory} />
      ) : null}
      <div className="view-toggle text-right mb-3 mx-2">
        <FaListUl
          size={30}
          onClick={() => setView('table')}
          style={{ cursor: 'pointer', marginRight: '20px', color: view === 'table' ? '#0b90cb' : '#6c757d' }}
        />
        <FaTable
          size={30}
          onClick={() => setView('list')}
          style={{ cursor: 'pointer', color: view === 'list' ? '#0b90cb' : '#6c757d' }}
        />
      </div>
      <div style={{ minHeight: "500px", minWidth: "95%" }} className="mb-5 mx-2">
        {loading && !filteredList ? (
          <div className="text-center">
            <Blocks
              type="Puff" // Choose the type of spinner you want (e.g., "Puff" or "ThreeDots")
              color="#00BFFF" // Set the color of the spinner
              height={100} // Set the height of the spinner
              width={100} // Set the width of the spinner
            />
            <p>Loading...</p>
          </div>
        ) : null}
        {filteredList ? (
          view === 'list' ? (
            <InventoryList items={filteredList} addToCart={handleAddToCart} />
          ) : (
            <InventoryTable items={filteredList} addToCart={handleAddToCart} />
          )
        ) : (
          <p className="text-center">No results.</p>
        )}
        {!filteredList && !loading && <p className="text-center">No results.</p>}
      </div>
      {/* Other components and logic */}
    </div>
  );
}

export default InventoryManagement;
