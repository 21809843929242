import React, { useEffect, useState } from "react";
import { useInventoryContext } from "../../context/InventoryContext";

import AppCustomerInfo from "../AppCustomerInfo";
import { Blocks } from "react-loader-spinner";
import InventoryList from "../../components/InventoryList";
import './styles.css';
import ShippingAddressForm from "../../components/ShippingAddressForm";
import { formatAPIisExpecting } from "./sampleData";
import { fetchWrapper, transformOrderData } from "../../lib/helper";
import NotesForm from "./components/Notes";
const searchBoxStyles = {
  fontSize: "1.5rem",
  padding: "15px 20px",
  borderWidth: "3px", // Adjust this value for the desired border thickness
};

function Cart(props) {
  const {
    cartCount,
    cartItems,
    setCartItems,
    handleAddToCart,
    getInventory,
    filterInventory,
    filteredList,
    inventory,
    inventoryList,
    orders,
    customerId,
    updateInventory,
    updateOrders,
    updateCustomerId,
    user,
  } = useInventoryContext();

  const [page, setPage] = useState(1);
  const [items, setItems] = useState(null);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(formatAPIisExpecting);
  const [success, setSuccess] = useState(false);
  const removeItem = (index) => {
    setCartItems((currentItems) => currentItems.filter((_, i) => i !== index));
  };

  const handleSaveShippingAddress = (addressData) => {
    console.log('Shipping Address in cart:', addressData);
    //merge the address data with the order data
    setOrder((prevOrder) => ({
      ...prevOrder,
      shipTo: addressData,
    }));
    // Here you can handle the shipping address data, like updating the state or making an API call
  };
  const handleSaveNotes = (notes) => {
    //merge the address data with the order data
    setOrder((prevOrder) => ({
      ...prevOrder,
      notes:notes
    }));
    // Here you can handle the shipping address data, like updating the state or making an API call
  };
  const processOrder = () => {
    console.log('order to process:', order);
    
    // Make the API call to process the order
    const postOrderData = transformOrderData(order);
    const response = fetchWrapper.post( `${process.env.REACT_APP_ROOT_URL}/api/customer/order`, postOrderData);
    response.then((response) => {  
   
    return response.json();
  }).then((response) => {
      if(response.success)  
      {
      console.log('Order processed:', response);
      setSuccess(cartItems)
      // Update the orders state with the new order
      updateOrders([...orders, response]);
      // Clear the cart items
      setCartItems([]);
    }
    if(response.error)
    {
      alert('Order failed: please try again.  If the error persists, contact us (peter@belcastroinc.com).');
      console.log('Order failed:', response.error);
    }
  }).catch((error) => {
    console.error("Error processing order:", error);
    alert('Order failed: please try again.  If the error persists, contact us (peter@belcastroinc.com).');
  })
  }
  useEffect(() => {
    //make updates every time the cart items change
    console.log('cart items in cart ', cartItems);
    setOrder((prevOrder) => ({
      ...prevOrder,
      orderItems: cartItems.map((item) => ({sku: item.sku, quantity: item.quantity ? item.quantity : 1, referenceItemId: item.receiveItemId?item.receiveItemId:"none"})), 
    }));
  }, [cartItems]);
 
  const handleQuantityChange = (sku, newQuantity) => {
    // Update cartItems state with the new quantity for the item with the matching sku
    setCartItems(currentItems =>
      currentItems.map(item =>
        item.sku === sku ? { ...item, quantity: newQuantity } : {...item, quantity: 1 }
      )
    );
  };
  
  return (
    <div className="border " style={{marginTop:90}} >
       <AppCustomerInfo />
     
      <div style={{ minHeight: "500px",  marginLeft:10, marginRight:10 }} className="mb-5">
       
        {loading && !cartItems ? (
          <div className="text-center">
            <Blocks
              type="Puff" // Choose the type of spinner you want (e.g., "Puff" or "ThreeDots")
              color="#00BFFF" // Set the color of the spinner
              height={100} // Set the height of the spinner
              width={100} // Set the width of the spinner
            />
            <p>Loading...</p>
          </div>
        ) : (
          <div className={success ? 'container d-none':''}>
          <div className="mt-4 row">
            <div className="col-12 table-responsive">
            <table className="table  table-bordered table-striped rounded-table">
            <thead className="thead-dark">
                <tr>
                  <th>Desc.</th>
                  <th>SKU</th>
                  <th>RecItemID</th>
                  <th>RecID</th>
                  <th>Ordered By</th>
                  <th>Date Added</th>
                  <th>Quantity</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {cartItems ? cartItems.map((item, index) => {
                  console.log('cart item ', item);
                  return (
                  <tr key={index}>
                    <td>{item.description}</td>
                    <td>{item.sku}</td>
                    <td>{item.receiveItemId}</td>
                    <td>{item.receiverId}</td>
                    <td>{item.userEmail}</td>
                    <td>{item.dateAdded}</td>
                    <td>
                    <select
  className="form-select"
  defaultValue="1" // Consider using a state or prop if you want to track selected values
  onChange={(e) => handleQuantityChange(item.sku, parseInt(e.target.value, 10))}
>
  {[1, 2, 3, 4, 5, 6, 7].map((quantity) => (
    <option key={quantity} value={quantity}>
      {quantity}
    </option>
  ))}
</select>
                    </td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() => removeItem(index)}
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                )
                        }): null
                }
              </tbody>
            </table>
          </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
          <ShippingAddressForm onSave={handleSaveShippingAddress} handleShippingAddressChange={handleSaveShippingAddress}/>
          </div>
          <div className="col-sm-6">
          <NotesForm onSave={handleSaveNotes} handleNotesChange={handleSaveNotes}/>
          </div>
          </div>
          <button className="btn btn-primary" onClick={() =>{processOrder()}}>Submit Order</button>
   
          </div>
        )}
        {success && (
  <div className="mt-5">
  <h2>Your Order Was Successfully Placed</h2>
  <table className="table table-success">
    <thead>
      <tr>
        <th>Description</th>
        <th>SKU</th>
        <th>Receive Item ID</th>
        <th>Receiver ID</th>
        <th>User Email</th>
        <th>Date Added</th>
        <th>Quantity</th>
      </tr>
    </thead>
    <tbody>
      {success && success.map((item, index) => (
        <tr key={index} className="table-success">
          <td>{item.description}</td>
          <td>{item.sku}</td>
          <td>{item.receiveItemId}</td>
          <td>{item.receiverId}</td>
          <td>{item.userEmail}</td>
          <td>{item.dateAdded}</td>
          <td>{item.quantity}</td>
        </tr>
      ))}
    </tbody>
  </table>
  </div>
)}
      </div>
      {/* success holds the cart items that were ordered */}
      


      </div>
  );
}

export default Cart;
