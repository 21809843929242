import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";
const BackgroundVideo = ({ buttonAction,buttonActionTwo, title, subtitle, button, buttonTwo }) => {
  return (
    <div className="background-video">
      <video className="video" autoPlay muted loop id="background-video">
        <source src="/videos/cb logistics headerv2.mp4" type="video/mp4" />
      </video>
      <div className="contentVideo">
        <h1 className="background-video-title">{title ? title : "Welcome"}</h1>
        <div className="subtitle d-none d-md-block">{subtitle ? subtitle : ""}</div>
        {/* <Link to="/contact"> */}
        {button ? (
          <button onClick={buttonAction} className="button buttonRed" style={{backgroundColor:'#C22130'}}>
            {button ? button : ""}
          </button>
        ) : (
          ""
        )}
         {buttonTwo ? (
          <button onClick={buttonActionTwo} className="button ml-2" style={{backgroundColor:"transparent", border:"2px solid white", color:"white"}}>
            {buttonTwo ? buttonTwo : ""}
          </button>
        ) : (
          ""
        )}
        {/* </Link> */}
      </div>
    </div>
  );
};

export default BackgroundVideo;
