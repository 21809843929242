//functional react component
import React, { useEffect } from "react";

import BackgroundVideo from "../../components/BackgroundVideo";
import BackgroundStatic from "../../components/BackgroundStatic";
import HoverSquareRow from "../../components/HoverSquareRow";
import Footer from "../../components/Footer";
import RowSplit from "../../components/RowSplit";
import GalleryComponent from "../../components/GalleryComponent";

const ComingSoon = () => {
  const title = "Coming Soon";
  const subtitle =
    "We are working hard to bring you the best experience possible.";
  const button = "Contact Us";
  useEffect(() => {
    const scrollToTop = () => {
      const scrollOptions = {
        top: 0,
        behavior: "smooth",
        duration: 1000, // 1 second
      };
      window.scrollTo(scrollOptions);
    };

    scrollToTop();
  }, []);
  return (
    <div>
      <BackgroundVideo title={title} subtitle={subtitle} button={button} />

      <Footer />
    </div>
  );
};
export default ComingSoon;
