import React, { useEffect } from "react";
import "./styles.css";
import BackgroundVideo from "../../components/BackgroundVideo";
import ParallaxComponent from "../../components/ParallaxContainer";
import BackgroundStatic from "../../components/BackgroundStatic";
import RowSplit from "../../components/RowSplit";
const CaseStudies = () => {
  const title = "Case Studies";
  const subtitle =
    "CB Logistics is trusted by the best. Below are a few case studies of our work.";
  const button = "";

  const rows = [
    {
      image: "/images/harbor-ucla.jpeg",
      title: "Harbor-UCLA Medical Center",
      paragraph: [
        "In late 2022, Rosendin and Morrow Meadows approached CB Logistics for help, both firms are working on a major five-year project – Harbor-UCLA Medical Center Replacement.  The firms are experiencing a challenge that has become common across the industry – significant shifts in material pricing and volatile project costs. ",
      ],
      button: "Learn More",
      link: "/case-studies/ucla-harbor",
    },
  ];
  useEffect(() => {
    const scrollToTop = () => {
      const scrollOptions = {
        top: 0,
        behavior: "smooth",
        duration: 1000, // 1 second
      };
      window.scrollTo(scrollOptions);
    };

    scrollToTop();
  }, []);
  return (
    <div>
      <BackgroundVideo title={title} subtitle={subtitle} button={button} />
      <BackgroundStatic />
      {/* <ParallaxComponent /> */}
      <div style={{ height: "600px", width: "100%" }}></div>
      <div style={{ height: "50%", height: 400, background: "white" }}>
        <h1>Case Studies</h1>
        <p>
          Construction isn't just about materials; it's about strategy,
          efficiency, and innovation. At CB Logistics, we are trusted with
          logistics and storage solutions by the best.{" "}
        </p>
        <p>But don't just take our word for it.</p>
        <p>
          Browse our collection of case studies that showcase how we turn
          challenges into victories. From large-scale projects to customized
          storage solutions, our real-world solutions demonstrate how we
          guarantee timely and budget-friendly results. Dive in and discover how
          we've helped partners like you build.
        </p>

        {rows.map((item, index) => (
          <RowSplit
            background={"#002a6a"}
            color={"white"}
            image={item.image}
            title={item.title}
            paragraph={item.paragraph}
            buttonLabel={item.button}
            link={item.link}
            titleSize="2rem"
            textSize={"1rem"}
            buttonText="Learn More"
            imageOnLeft={index % 2 === 0} // or imageOnLeft={false} for image on the right
          />
        ))}
      </div>
    </div>
  );
};

export default CaseStudies;
