import React, { useState, useEffect } from 'react';
import './styles.css'; // Ensure this path matches the location of your CSS file

function NotesForm({ onSave, handleNotesChange }) {
  const [formData, setFormData] = useState({
    notes: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    handleNotesChange(formData);
  }, [formData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="notes-form-container">
      <h3>Notes</h3>
      <div className="mb-0">
        <label htmlFor="notes" className="notes-form-label">Include any shipping instructions here</label>
        <textarea 
        style={{width: '100%', height:200, background:'#222', color:'white', fontSize:16, padding:10, borderRadius:5, border:'1px solid #333'}}
          className="notes-form-textarea" 
          id="notes" 
          name="notes" 
          value={formData.notes} 
          onChange={handleChange} 
          required 
        />
      </div>
      {/* <button type="submit" className="notes-form-button btn btn-info mt-2">Save Notes</button> */}
    </form>
  );
}

export default NotesForm;
