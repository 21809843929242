import React, { useState } from "react";

function ResetPasswordForm() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const token = new URLSearchParams(window.location.search).get("token");

  const passwordsMatch = password === confirmPassword;

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!passwordsMatch) {
      alert("Passwords don't match!");
      return;
    }
    //not working yet
    //const url = `${process.env.REACT_APP_ROOT_URL}/api/users/reset-password`;
    const url = `${process.env.REACT_APP_ROOT_URL}/api/users/reset-password`;
    console.log("url ", url);
    // Send the password and token to your server to handle the password reset.
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ password, token }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // Handle success (maybe redirect or show a success message)
          console.log("Success:", data);
        } else {
          // Handle failure (show an error message)
          console.log("Failure:", data);
        }
      });
  };

  return (
    <div className="container " style={{ marginTop: "300px" }}>
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-header">Reset Password</div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="password">New Password:</label>
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="confirmPassword">Confirm Password:</label>
                  <input
                    type="password"
                    id="confirmPassword"
                    className={`form-control ${
                      confirmPassword
                        ? passwordsMatch
                          ? "is-valid"
                          : "is-invalid"
                        : ""
                    }`}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                  {confirmPassword &&
                    (passwordsMatch ? (
                      <div className="valid-feedback">Passwords match.</div>
                    ) : (
                      <div className="invalid-feedback">
                        Passwords do not match.
                      </div>
                    ))}
                </div>
                <button type="submit" className="btn btn-primary">
                  Reset Password
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordForm;
