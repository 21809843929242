import React from "react";

const TextWithNewLines = ({ text }) => {
  return (
    <ul>
      {text.split("\n\n").map((line, index) => (
        <React.Fragment key={index}>
          <li>{line}</li>
          <br />
          <br />
        </React.Fragment>
      ))}
    </ul>
  );
};

export default TextWithNewLines;
