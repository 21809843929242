import React from "react";
import { Container, Row, Col, Card, CardImg, CardTitle } from "reactstrap";

const GalleryComponent = ({
  background,
  title = "Clients",
  description = "",
  gallery = [],
  showCompanyTitles = true,
}) => {
  return (
    <div
      style={{ background: background ? background : "white" }}
      className="content-container"
    >
      <Row className="mx-sm-5">
        <Col>
          <h2>{title}</h2>
          <p className="text-center">{description}</p>
        </Col>
      </Row>
      <Row className="mx-5">
        {gallery.map((image, index) => (
          <Col xs={12} sm={4} md={3} lg={3} key={index}>
            <Card style={{ border: "solid 0 black", padding: 30 }}>
              <a href={image.link} target="_blank" rel="noopener noreferrer">
                <CardImg
                  top
                  width="100%"
                  style={{ maxHeight: "200px" }}
                  src={image.img}
                  alt={image.title}
                />
                {showCompanyTitles ? (
                  <CardTitle>{image.title}</CardTitle>
                ) : null}
              </a>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default GalleryComponent;
