export const services = [
  {
    image: "/images/storage.png",
    title: "Managed Storage",
    paragraph: [
      " We offer flexible storage facilities tailored to your project's unique demands. ",
      "Our pay-as-you-go model is designed to respond to your ever-changing storage needs. Just pay for what you actually use.",
      "Our fixed cost model is designed for those lookeing to avoid monthly bills.",
      "Trust us to handle your storage so you can focus on what you do best – build.",
    ],
    button: "Lets Get Started",
    link: "/contact",
  },
  {
    image: "/images/shipping.png",
    title: "Material Transportation and Delivery",
    paragraph: [
      "Efficient material delivery and handling form the lifeblood of any construction project, and at CB Logistics, we are the experts you can trust. We offer comprehensive services for transporting materials to and from your worksite, providing you with the certainty that your materials will arrive exactly when and where they're needed.",
      "We've integrated advanced third-party logistics (3PL) into our services. This technology allows you to track your materials in real-time, giving you full visibility of your supplies from the moment they leave the warehouse until they arrive at the construction site.",
      // "But we don’t just deliver; we handle your materials with utmost care and professionalism. Our skilled team is trained in best-practice procedures to ensure your materials arrive in pristine condition, ready for immediate use.",
    ],
    button: "Lets Get Started",
    link: "/contact",
  },
  {
    image: "/images/procurement2.png",
    title: "Procurement",
    paragraph: [
      "At CB Logistics, we transform the complex task of procurement of building supplies into a seamless, stress-free experience. ",

      "Our specialized services streamline every step of the procurement journey, from procuring comprehensive price quotes to submitting meticulous purchase orders, tracking payments, and ensuring fulfillment. Our dedicated team of experts keeps track of the minutiae, allowing you to focus on the bigger picture of your construction project.  ",
      "When you choose CB Logistics, you're not just choosing a procurement service—you're choosing a partner committed to the successful realization of your construction vision.",
    ],
    button: "Lets Get Started",
    link: "/contact",
  },
];

export const dvbeData = [
  {
    image: "/images/usa-flag.jpeg",
    title: "DVBE",
    paragraph: [
      "Complete DVBE requirements with real veterans.",

      `CB Logistics is a Disabled Veteran Business Enterprise (DVBE) and Small Business Enterprise (SBE) that
      has been providing construction services since 2018. We make it simple to fulfill DVBE requirements
      with construction services: material
      management, logistics, rentals, wholesale electrical, and construction management. Founded
      by entrepreneur Peter Belcastro, who served as a member of the United States Naval Construction
      Force from 2007 to 2015, our team consists of leaders who have received extensive training and
      experience and are dedicated to operational excellence.`,
    ],
    button: "Learn More",
    link: "/contact",
  },
];
