import React, { Children } from "react";
import styles from "./styles.css";
import { Col, Row } from "reactstrap";

const ShowBanner = ({ title, backgroundImage, children }) => {
  return (
    <div
      className={"banner"}
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className={"gradient-overlay"}></div>
      <div className={"title"}>{title}</div>
      {children ? <div className="childComponent">{children}</div> : ""}
    </div>
  );
};

export default ShowBanner;
