export const data = {
   "construction-logistics": {
    title:"Construction Logistics",
    img:'/images/services/banner-logistics.png',
    paragraphs:[
        {
            title:'Construction Logistics',
            description:'CB Logistics offers specialized Construction Logistics Services designed to support the unique needs of the construction industry. Our comprehensive solutions streamline the transportation and handling of construction materials, equipment, and supplies, ensuring timely and efficient project execution. '
            
        },
       
    ],
    listImages:['/images/services/logistics.png', '/images/services/cargo-truck2.jpg'], 
    listTitle:'Key features of our Construction Logistics Services include:',    
    list:[
       { title:'Supply Chain Management:',
        text: 'We manage the entire supply chain process, from procurement and transportation to inventory management and distribution, to ensure seamless operations for construction projects.',
    },
    { title:'Material Handling and Transportation:',
        text: 'Our expertise in handling heavy and oversized construction materials, equipment, and machinery ensures safe and efficient transportation to the project site.',
    },
    { title:'Site Logistics Planning:',
        text: 'We develop customized site logistics plans tailored to each construction project, optimizing workflows and minimizing downtime.',
    },
    { title:'Inventory Control:',
        text: 'Utilizing advanced inventory management systems, we provide real-time visibility and control over construction materials and supplies, reducing waste and enhancing productivity.',
    },
    { title:'Last-Mile Delivery:',
        text: 'Our logistics solutions include last-mile delivery services, ensuring that materials and equipment are delivered to the right location on time, even in challenging project environments.',
    },
    { title:'Project Coordination:',
        text: 'We collaborate closely with contractors, suppliers, and project managers to coordinate logistics activities and ensure smooth project progress.',
    },
    { title:'Safety and Compliance:',
        text: 'CB Logistics prioritizes safety and compliance with industry regulations, implementing best practices and protocols to safeguard workers, materials, and the environment.',
    },
    { title:'Partner with CB Logistics for reliable and efficient Construction Logistics Services that contribute to the success of your construction projects.',
        text: '',
    },
    ],
},
"ground-freight": {
    title:"Ground Freight",
    img:'/images/services/banner-logistics.png',
    paragraphs:[
        {
            title:'Ground Freight',
            description:`CB Logistics offers adaptable freight forwarding services to diverse industries such as construction, medical devices, e-commerce, and more. Our team of logistics experts designs cost-effective and dependable supply chain services tailored to each customer's specific requirements. Whether you need domestic or international shipping, white-glove delivery, managed services, or time-critical solutions, Omni Logistics is equipped to manage your freight needs worldwide.  As a leading logistics company, Omni maintains strong global partnerships to deliver top-tier freight forwarding services across North America, Europe, and Asia. Partner with us for best-in-class solutions that ensure efficient and reliable freight management for your business. `
        }
       
    ],
    
    
},
// Freight Forwarding
"freight-forwarding":{
    title:"Freight Forwarding",
    img:'/images/services/banner-logistics.png',
    paragraphs:[
        {
            title:'Freight Forwarding',
            description:'CB Logistics offers comprehensive freight forwarding services to streamline the transportation of goods across the globe. Our team of experts manages the entire shipping process, from booking and documentation to customs clearance and delivery, ensuring a seamless and efficient experience for our clients. '
            
        },
       
    ],
    listImages:['/images/services/freight-forward.jpeg', '/images/services/logistics.png'],
    listTitle:'Our freight forwarding services include:',
    list:[
        { title:'Full Truckload (FTL)',
              },
        
        { title:'Less Than Truckload (LTL)',
            }
        ,
        { title:'24/7 Customer support for tracking, quotes, or bookings',
            },
        { title:'Air Ride Equipment',
            },
        { title:'GPS satellite tracking',
            },
        { title:'Single and team drivers',
            },
        { title:'Online manifest and tracking',
            },
        { title:'Drop trailers for pre-loading or delayed unloading',
            },
        { title:'Quotes provided within five minutes',
            },
        { title:'Multiple-stop deliveries with driver-assist unload',
            },
        { title:'Time critical domestic ground',
            },
        { title:'Flatbed',
            },
        { title:'Special equipment',
            },
        { title:'Local pick up & delivery',
            },
    ],
},

// Warehousing & Distribution
"warehousing-distribution":{
    title:"Warehousing & Distribution",
    img:'/images/services/banner-logistics.png',
    paragraphs:[
        {
            title:'Warehousing & Distribution',
            description:'CB Logistics offers comprehensive Warehousing & Distribution Services tailored to meet your unique needs. Our state-of-the-art facilities and advanced inventory management systems ensure efficient handling and storage of your goods. From receiving and storage to order fulfillment and distribution, we provide end-to-end solutions that streamline your supply chain operations.'
            
        },
       
    ],
    listImages:['/images/services/warehouse.jpeg', '/images/services/logistics.png'],
    listTitle:'Our warehousing services include:',
    list:[
        { title:'Secure storage:',
            text: 'We maintain secure and climate-controlled storage facilities to protect your inventory.',
        },
        { title:'Inventory management:',
            text: 'Utilizing advanced technology, we offer real-time visibility and accurate inventory tracking.',
        },
        { title:'Order fulfillment:',
            text: 'Our efficient order picking and packing processes ensure timely and accurate order fulfillment.',
        },
        { title:'Value-added services:',
            text: 'From labeling and packaging to kitting and assembly, we offer customized value-added services to enhance your operations.',
        },
    ],
}

}
/*
Ground Freight
CB Logisitics ground freight shipping services cover a wide range of domestic trucking solutions. We take a personalized approach to comprehend your business needs and customize our services to meet your precise specifications. Our truckload division complements our fleet of trucks along with a network of approved truck carriers by offering spot resources, ensuring comprehensive end-to-end coverage for your shipments.
Services
Full Truckload (FTL)
Less Than Truckload (LTL)
24/7 Customer support for tracking, quotes, or bookings
Air Ride Equipment
GPS satellite tracking
Single and team drivers
Online manifest and tracking
Drop trailers for pre-loading or delayed unloading
Quotes provided within five minutes 
Multiple-stop deliveries with driver-assist unload
Time critical domestic ground
Flatbed
Special equipment
Local pick up & delivery

*/


