import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './styles.css'; // Make sure your CSS file is correctly linked here

const GalleryItem = ({ image, title, goHere, slug }) => {
  return (
    <Col md={6} className="mb-4">
      <div className="s-gallery-item" onClick={() => goHere({ slug })} role="button">
        <img src={image} alt={title} className="img-fluid" style={{ width: '100%', height: '400px' }} />
        <div className="s-overlay">
          <div className="s-text">{title}</div>
        </div>
      </div>
    </Col>
  );
};

const Gallery = ({ galleryItems, goHere }) => {
  let galleryItemsDefault = [
    {
      image: '/images/services/cargo-truck2.jpg',
      title: 'Ground Freight',
      slug: '/services/ground-freight'
    },
    {
      image: '/images/services/freight-forward.jpeg',
      title: 'Freight Forwarding',
      slug: '/services/freight-forwarding'
    },
    {
      image: '/images/services/warehouse.jpeg',
      title: 'Warehousing & Distribution',
      slug: '/services/warehousing-distribution'
    },
    {
      image: '/images/services/logistics.png',
      title: 'Construction Logistics',
      slug: 'services/construction-logistics'
    }
  ];

  if (!galleryItems) {
    galleryItems = galleryItemsDefault;
  }

  return (
    <div className="gallery-background">
      <div className="gallery-overlay"></div>
      <Container style={{ position: 'relative', zIndex: 1 }}>
        <Row>
          {galleryItems.map((item, index) => (
            <GalleryItem key={index} image={item.image} title={item.title} goHere={goHere} slug={item.slug} />
          ))}
        </Row>
      </Container>
      
    </div>
  );
};

export default Gallery;
