import React, { useState, useEffect } from "react";
import axios from "axios";
import "./styles.css";
import { useInventoryContext } from "../../context/InventoryContext";

const rootUrl = process.env.REACT_APP_ROOT_URL || "http://localhost:3030";
const CustomerInfo = () => {
  let _user = null;
  const {
    customerInfo,
    user,
    updateCustomerId,
    setCustomerInfo
  } = useInventoryContext();
  
  
 
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  
  useEffect(() =>   {
    console.log("STARTING CUSTOMER INFO");
    if(!user || !user.cbId) 
      {
        console.log('no user');
        _user = localStorage.getItem('cbUser');
        console.log('local storage user', _user);
        _user = JSON.parse(_user);
      }
      else
      {
        _user = user;
        
      }
      console.log('_user ', _user, 'user ', user);
    // Get token from local storage
    const token = localStorage.getItem("authToken");
    // Configuration for the request
    const config = {
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    };

    // Make the API call here 
    //TODO --- lift this up into the context
    if(!_user || !_user.cbId) {console.log("no user.cbid");return;}
    const url = `${rootUrl}/api/customer_info/${_user.cbId}`;
    console.log('url in appcustomerinfo', url);
    axios.get(url, config)
      .then((response) => {
        console.log("Customer data:", response);
       
        setCustomerInfo(response.data.customerInfo);
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
      });
  }, []);

  const handleCardClick = (customerId) => {
    console.log("Selected customer ID:", customerId);
    setSelectedCustomerId(customerId);
    updateCustomerId(customerId);
  };

  if (!customerInfo ) {
    return <p>Loading...</p>;
  }

  return (
    <div className="customer-profile-container">
    {customerInfo && customerInfo.length && customerInfo.map((customer, index) => (
      <div
        key={index}
        className={`profile-card company-info border text-left mx-2 ${selectedCustomerId === customer.customer_id ? 'selected' : ''}`}
        onClick={() => handleCardClick(customer.customer_id)}
      >
        <div className="logo-container">
          {customer.logo ? (
            <img src={customer.logo} alt={`${customer.company} logo`} />
          ) : (
            <img src="/images/default-logo.png" alt="default logo" />
          )}
        </div>

        <div className="info-container">
          <p>
            <strong>Company</strong>
          </p>
          <p>{customer.company}</p>

          <p>
            <strong>Address</strong>
          </p>
          <p>{customer.address}</p>

          <p>
            <strong>Contact Number</strong>
          </p>
          <p>{customer.number}</p>

          <p>
            <strong>Email</strong>
          </p>
          <p>
            <a href={`mailto:${customer.email}`}>
              {customer.email}
            </a>
          </p>
        </div>
      </div>
    ))}
  </div>
  );
};

export default CustomerInfo;
