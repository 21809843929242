import "./App.css";
import Routes from "./routes";
import Header from "./components/Header";
import { InventoryProvider } from "./context/InventoryContext";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
console.log("react root process ", process.env.REACT_APP_ROOT_URL);

function App() {
  
  return (
    <div className="App">
      <InventoryProvider>
        {/* <Header /> */}
        
     
        <Routes />
      </InventoryProvider>
    </div>
  );
}

export default App;
