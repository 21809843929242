import React, { useEffect } from "react";
import "./styles.css";
import BackgroundVideo from "../../components/BackgroundVideo";
import ParallaxComponent from "../../components/ParallaxContainer";
import BackgroundStatic from "../../components/BackgroundStatic";
const About = () => {
  const title = "CB Logistics";
  const subtitle =
    "A Disabled Veteran Business Enterprise (DVBE) and Small Business Enterprise (SBE) providing construction logistics and managerial services since 2020";
  const button = "Learn More";

  return (
    <div>
      <BackgroundVideo title={title} subtitle={subtitle} button={button} />
      <BackgroundStatic />
      {/* <ParallaxComponent /> */}
      <div style={{ height: "600px", width: "100%" }}></div>
      <div style={{ height: "50%", height: 400, background: "white" }}>
        <h1>About</h1>
        <p>
          CB Logistics is a Disabled Veteran Business Enterprise (DVBE) and
          Small Business Enterprise (SBE) that has been providing construction
          logistics and managerial services to Owners and General Contractors
          since 2020. Operating as a DVBE/SBE, allows us an advantage of
          providing high quality services that meet federal standards, goals,
          and requirements. Our organization offers quality management and
          timely performance on all types of projects. We are very enthusiastic
          and passionate about providing the best service to our customers. At
          CB Logistics we utilize experienced Construction Professionals to
          modernize Procurement and Logistics for the Construction Industry
        </p>
      </div>
    </div>
  );
};

export default About;
