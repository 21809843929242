import React from "react";
import { FaMapMarkerAlt, FaPhone } from "react-icons/fa";
import "./styles.css";

const Contact = ({ address, phone }) => {
  return (
    <div className="contact-container">
      <div className="logo">
        {/* Add your logo here */}
        <img
          src="https://www.belcastro.group/images/logos/LOGO%20-%20CB%20Logistics%20-%20square%20-%20360x360.webp"
          alt="Logo"
        />
      </div>
      <div className="contact-info">
        <div className="contact-item">
          <p>
            <FaMapMarkerAlt className="contact-icon" />
            {address ? address : ""}
          </p>
        </div>
        <div className="contact-item">
          <p>
            {" "}
            <FaPhone className="contact-icon" />
            {phone ? phone : ""}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
