import React from "react";
import GoogleMapReact from "google-map-react";
const googleApi = process.env.REACT_APP_GOOGLE_MAP;
const Marker = () => (
  <div
    style={{
      position: "absolute",
      transform: "translate(-50%, -50%)",
      top: "50%",
      left: "50%",
      width: "20px",
      height: "20px",
      background: "#002a6a",
      borderRadius: "50%",
    }}
  ></div>
);
const GoogleMap = ({
  lat = 33.871430,
  lng = -118.207380,
  address,
  phone,
}) => {
  // Google Map configuration
  const mapOptions = {
    center: { lat, lng }, // Coordinates for the map center
    zoom: 12, // Zoom level for the map
  };

  return (
    <div>
      {/* Google Map */}
      <div style={{ height: "400px", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDeDR4EYcDCjpdU66McbX2gjhD_ABjEZqg" }} // Replace with your own API key
          defaultCenter={mapOptions.center}
          defaultZoom={mapOptions.zoom}
        >
          <Marker lat={lat} lng={lng} />
        </GoogleMapReact>
      </div>

      {/* Contact Information */}
      {/* <div>
        <h2>Contact Us</h2>
        <p>{address ? address : ""}</p>
        <p>Phone: {phone ? phone : phone}</p>
      </div> */}
    </div>
  );
};

export default GoogleMap;
