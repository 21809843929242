const data = [
  {
    url: "ucla-harbor",
    title:
      "Case Study: Streamlining Materials Procurement, Storage, and Logistics in a Volatile Market",
    description:
      'This case study demonstrates how CB Logistics helps protect customers\' budgets and time through their "Amazon for construction" solutions.',
    images: [
      "harbor-ucla.jpeg",
      "harbor-ucla1.png",
      "morrowmeadows.png",
      "harbor-ucla.jpeg",
      "harbor-ucla2.jpeg",
      "harbor-ucla3.jpeg",
    ],
    sections: [
      {
        title: "Situation",
        content:
          "In late 2022, Rosendin and Morrow Meadows approached CB Logistics for help, both firms are working on a major five-year project – Harbor-UCLA Medical Center Replacement.\n\n The firms are experiencing a challenge that has become common across the industry – significant shifts in material pricing and volatile project costs.",
        images: ["morrowmeadows.png", "rosendin.png"],
      },
      {
        title: "Task",
        content:
          "Create a solution that would mitigate risks associated with long lead times for materials. \n\nBoth firms needed procurement support, warehouse and storage, space for prefabrication, and efficient material handling and delivery to the project site.",
      },
      {
        title: "Action",
        content:
          "Recognizing the risks posed to General Contractors' margins and the complexity of the situation, CB Logistics leveraged their expertise in construction to implement a comprehensive solution that revolves around procurement and fulfillment, storage, using advanced technology for real-time inventory status and tracking, and distribution to project sites to ensure seamless coordination of materials. \n\nTheir customized plan included acquiring a 67,000-square-foot warehouse space co-selected by Rosendin and Morrow Meadows.",
      },
      {
        title: "Results",
        content:
          "CB Logistics' solution and services yielded significant benefits for Rosendin and Morrow Meadows.\n\nEnhanced Material Tracking – CB Logistics' system enables easy identification and retrieval of materials. Instead of searching for specific items in an unorganized warehouse, project stakeholders are able to digitally locate the required materials, saving time and effort.\n\n Reduced Labor and Management Costs – By leveraging CB Logistics' expertise in construction to procure common materials and their inventory and tracking system, the project team minimizes the labor and management costs. Plus, this streamlined process allows for better resource allocation and increased productivity.\n\n Cost Savings/Avoiding Cost Escalation – CB Logistics helped Rosendin and Morrow Meadows save money by purchasing materials upon securing the contract and avoiding cost escalation over the five-year project duration. Additionally, this system facilitates early payment for job-site materials for better cash flow and reduced financial burden.\n\n Reduction in Material Delays – CB Logistics' efficient warehouse procurement and logistics processes ensured a steady and timely supply of materials. By avoiding delays, the project team maintained progress without interruptions, thus enhancing overall project efficiency.\n\n Lower Transportation Costs – CB Logistics' single-project and direct to job site delivery approach significantly reduced transportation costs and mitigated risks associated with multiple transfers. This streamlined approach allowed for efficient material handling and contributed to a smoother project execution.",
      },
    ],
  },
];

export default data;
