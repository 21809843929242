function formatDate(dateString) {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate = new Date(dateString).toLocaleString("en-US", options);
  return formattedDate;
}

const fetchWrapper = {
  get,
  post,
  put,
  delete: _delete,
  postPromise
};
const token = localStorage.getItem('authToken');

const defaultOptions = {
  credentials: 'include',
  mode: 'cors',
  // method: 'GET',
  // referrerPolicy: 'no-referrer',
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

function get(url, options) {
  let requestOptions = defaultOptions;
  if (options) {
    requestOptions = options;
  }

  console.log('fetchWrapper.get() ', requestOptions, 'url ', url);
  return fetch(url, requestOptions).then(handleResponse);
}

function post(url, body) {
  console.log('post body ', body);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  console.log('fetchWrapper.post() ', requestOptions, 'url ', url);
  return fetch(url, requestOptions).then(handleResponse);
}
function postPromise(url, body) {
  console.log('post body ', body);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  console.log('fetchWrapper.post() ', requestOptions, 'url ', url);
  return fetch(url, requestOptions);
}

function put(url, body) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url) {
  const requestOptions = {
    method: 'DELETE',
  };
  return fetch(url, requestOptions).then(handleResponse);
}


function handleResponse(response) {
  console.log('handleresponse ', response);
  // return response.text().then((text) => {
  //   const data = text && JSON.parse(text);
  //   console.log('data ', data);
  //   if (!response.ok) {
  //     const error = (data && data.message) || response.statusText;
  //     return Promise.reject(error);
  //   }

    return response;
  };
// convert the formData object to the format the API is expecting for order creation
function transformOrderData(formData) {
  console.log('formData ', formData);
  // Define the transformed object format
  const transformedData = {
    customerIdentifier: formData.customerIdentifier,
    facilityIdentifier: formData.facilityIdentifier,
    referenceNum: formData.referenceNum,
    
    shippingNotes: "Carrier specific shipping instructions",
    billingCode: "Prepaid",
    routingInfo: formData.routingInfo,
    shipTo: {
      companyName: formData.shipTo.companyName,
      name: formData.shipTo.name,
      address1: formData.shipTo.address1,
      address2: formData.shipTo.address2,
      city: formData.shipTo.city,
      state: formData.shipTo.state,
      zip: formData.shipTo.postalCode, // Changed to use the zip code from formData
      country: formData.shipTo.country
    },
    orderItems: formData.orderItems.map(item => ({
      // itemIdentifier: {sku: item.sku},
      itemIdentifier: { sku: item.sku },
      qty: item.quantity,
    })),
    ...formData.notes,
  };
  /*
   {
      itemIdentifier: {
        sku: "3-4-CAR038.a",
      },
      qty: 1,
    },
  */
  console.log('transformedData ', transformedData);
  return transformedData;
}

// Example usage with your formData
const formDataExample = {
  customerIdentifier: { id: "2" },
  facilityIdentifier: { id: "1" },
  referenceNum: "TEST2",
  notes: "Warehouse Instructions",
  routingInfo: {
    carrier: "UPS",
    mode: "92",
    scacCode: "UPGN",
    account: "12345z"
  },
  shipTo: {
    companyName: "zack",
    name: "Zack Prager",
    address1: "8805 N Courtenay Ave",
    address2: "",
    city: "Portland",
    state: "OR",
    postalCode: "97203",
    country: "United States"
  },
  orderItems: [
    {
      itemIdentifier: { sku: "Example" },
      qty: 1,
      receiveItemId: "number"
    }
  ]
};




export { fetchWrapper,formatDate, transformOrderData };
