import React from "react";
import axios from "axios";
import "./styles.css";
import { formatDate } from "../../lib/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReceipt } from "@fortawesome/free-solid-svg-icons";

const InventoryList = ({ items, addToCart, cartItems }) => {
  const handleDownloadReceipt = async (receiverId) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.get(`${process.env.REACT_APP_ROOT_URL}/api/customer/download-receipt/${receiverId}`, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error downloading receipt:", error);
    }
  };

  return (
    <div className="catalog-list">
      {items
        ? items.map((item) => {
            console.log('item ', item);
            return (
              <div key={item.receiveItemId} className="catalog-item">
                <div className="item-image">{/* Add your item image here */}</div>
                <div className="item-details">
                  <div className="item-title bg-secondary text-light text-center">
                    <div className="text-light font-weight-bold">
                      Description:
                    </div>
                    <div>{item.description}</div>
                  </div>
                  <div className="item-small-spacer"></div>
                  <p className="item-sku item-p mt-3">
                    <span className="font-weight-bold">ITEM ID(sku):</span>
                    {item.itemIdentifier.sku}
                  </p>
                  <p className="item-sku item-p">
                    <span className="font-weight-bold"> Reference #: </span>
                    {item.referenceNum}
                  </p>

                  <div className="item-small-spacer"></div>
                  <p className="item-available">
                    <span className="font-weight-bold">PO number: </span>
                    {item.poNum}
                  </p>
                  <p className="item-received">
                    <span className="font-weight-bold">Received: </span>
                    {item.received}
                  </p>
                  <p className="item-available">
                    <span className="font-weight-bold">Available: </span>
                    {item.available}
                  </p>

                  <div className="item-small-spacer"></div>
                  <p className="item-p">
                    <div className="strong font-weight-bold">Received Date: </div>
                    {formatDate(item.receivedDate)}
                  </p>

                  <p className="item-p mt-4 pb-3">
                    <div className="strong font-weight-bold">
                      Internal Ref Id (receiveItemId):
                    </div>
                    {item.receiveItemId}
                  </p>

                  <div className="bottom-spacer"></div>
                  <div className="d-flex flex-row align-items-center justify-content-between bottom-div ">
                    <div>
                      <img
                        src="/images/delivery.png"
                        title="Delivery Available"
                      />
                      <div className="text-muted">We Deliver</div>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => addToCart(item)}
                      >
                        Add to Cart
                      </button>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => handleDownloadReceipt(item.receiveItemId)}
                      >
                        <FontAwesomeIcon icon={faReceipt} /> View Receipt
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : "No results found."}
    </div>
  );
};

export default InventoryList;