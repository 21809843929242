import React, { useState } from "react";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import "./styles.css";
import { useInventoryContext } from "../../context/InventoryContext";

const StickerHeader = () => {
  const { logout, user, cartItems } = useInventoryContext();
  const [showMenu, setShowMenu] = useState(false);

  const isLoggedIn = Boolean(user || localStorage.getItem("authToken"));
  
  const handleMenuToggle = () => {
    setShowMenu(!showMenu);
  };

  const handleLogout = () => {
    logout();
    window.location.href = "/login";
  };

  console.log("user in header ", user, "is logged in ", isLoggedIn);

  return (
    <header className={`sticker-header ${showMenu ? "active" : ""}`}>
      <Navbar expand="md" variant="dark">
        <Navbar.Brand href="/" style={{ fontSize: "2rem", fontWeight: "bold" }}>CB Logistics</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-collapse" onClick={handleMenuToggle}>
          <FaBars />
        </Navbar.Toggle>
        <Navbar.Collapse id="navbar-collapse">
          <Nav className="ml-auto">
            <Nav.Link href="/">Home</Nav.Link>
            
            <NavDropdown title="Services" id="basic-nav-dropdown">
              <NavDropdown.Item href="/services/ground-freight">Ground Freight</NavDropdown.Item>
              <NavDropdown.Item href="/services/freight-forwarding">Freight Forwarding</NavDropdown.Item>
              <NavDropdown.Item href="/services/warehousing-distribution">Warehousing & Distribution</NavDropdown.Item>
              <NavDropdown.Item href="/services/construction-logistics">Construction Logistics</NavDropdown.Item>
            </NavDropdown>
            
            <Nav.Link href="/dvbe">DVBE</Nav.Link>
            <Nav.Link href="/contact">Contact</Nav.Link>
            <Nav.Link href="/inventory">Inventory</Nav.Link>
            <Nav.Link href=" https://belcastrogroup.blog/">Blog</Nav.Link>
            
            {isLoggedIn ? (
              <Nav.Link onClick={handleLogout} href="#">
                Logout
              </Nav.Link>
            ) : (
              <Nav.Link href="/login">Login</Nav.Link>
            )}
            
            <Nav.Link className="cart-icon" href="/cart">
              <i className="fa fa-shopping-cart"></i>
              {cartItems.length >= 0 ? (
                <span className="badge badge-pill ">{cartItems.length}</span>
              ) : (
                ""
              )}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default StickerHeader;
