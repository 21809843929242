import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Corrected import
import { fetchWrapper } from "../../lib/helper";
// Create the context
const InventoryContext = createContext();
const REACT_APP_ROOT_URL = "https://belcastro.us-3.evennode.com"; //"http://localhost:3030";s

// Create a custom hook to access the context
export const useInventoryContext = () => useContext(InventoryContext);
const rootUrl = process.env.REACT_APP_ROOT_URL || REACT_APP_ROOT_URL;
// Context Provider component
export function InventoryProvider({ children }) {
  const [inventory, setInventory] = useState(() => {
    const saved = localStorage.getItem("inventory");
    return saved ? JSON.parse(saved) : [];
  });

  const [orders, setOrders] = useState(() => {
    const saved = localStorage.getItem("orders");
    return saved ? JSON.parse(saved) : [];
  });

  const [customerId, setCustomerId] = useState(() => {
    const saved = localStorage.getItem("customerId");
    return saved ? JSON.parse(saved) : null;
  });

  const [customerInfo, setCustomerInfo] = useState(() => {
    const saved = localStorage.getItem("customerInfo");
    return saved ? JSON.parse(saved) : null;
  });

  const [inventoryList, setInventoryList] = useState(() => {
    const saved = localStorage.getItem("inventoryList");
    return saved ? JSON.parse(saved) : null;
  });

  const [filteredList, setFilteredList] = useState(() => {
    const saved = localStorage.getItem("filteredList");
    return saved ? JSON.parse(saved) : [];
  });

  const [loginError, setLoginError] = useState(() => {
    const saved = localStorage.getItem("loginError");
    return saved ? JSON.parse(saved) : false;
  });

  const [cartItems, setCartItems] = useState(() => {
    const saved = localStorage.getItem("cartItems");
    return saved ? JSON.parse(saved) : [];
  });

  const [cartCount, setCartCount] = useState(() => {
    const saved = localStorage.getItem("cartCount");
    return saved ? JSON.parse(saved) : 0;
  });
  const [user, setUser] = useState(null);

  const getInventory = ({ id }) => {
    console.log("getting inventory for customer ", `${rootUrl}/api/customer/inventory/${customerId}`);
    //get authToken from local storage
    const token = localStorage.getItem("authToken");
    //crete config headers for the request
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    axios
      .get(`${rootUrl}/api/customer/inventory/${customerId}`, config)
      .then((response) => {
        console.log("response from inventory ", response);
        //consolidate on sku and purchase order, add recieved and available
        const consolidatedInventory = [];
        const referenceNumCounter = {};
        const receiverIdCounter = {};
        const skuCounter = {};
        if(response.data.totalResults === 0){
          setInventoryList([]);
        setFilteredList([]);
      return;
      }
        // counts duplicates, not currently used
        response.data &&
          response.data._embedded &&
          response.data._embedded.item.forEach((item) => {
            referenceNumCounter[item.referenceNum] =
              (referenceNumCounter[item.referenceNum] || 0) + 1;
            receiverIdCounter[item.receiverId] =
              (receiverIdCounter[item.receiverId] || 0) + 1;
            skuCounter[item.itemIdentifier.sku] =
              (skuCounter[item.itemIdentifier.sku] || 0) + 1;
          });
        // Consolidate on sku
        const data =
          response.data &&
          response.data._embedded &&
          response.data._embedded.item;
        const consolidated = data.reduce((acc, item) => {
          // Check if the SKU is already in the accumulator
          const existing = acc.find(
            (e) => e.itemIdentifier.sku === item.itemIdentifier.sku
          );

          if (existing) {
            // Update the existing item
            existing.received += item.received;
            existing.available += item.available;
            existing.receivedDate += ", " + item.receivedDate;
            existing.receiveItemId += ", " + item.receiveItemId;
          } else {
            // Clone the item and add it to the accumulator
            const newItem = { ...item };
            newItem.receivedDate = "" + newItem.receivedDate;
            newItem.receiveItemId = "" + newItem.receiveItemId;
            acc.push(newItem);
          }
          return acc;
        }, []);

        console.log("consolidated inventory ", consolidated);

        //setInventoryList(response.data);
        setInventoryList(consolidated);
        setFilteredList(consolidated);
      })
      .catch((error) => {
        console.error("Error fetching item data:", error);
      });
  };

  const filterInventory = ({ searchTerm }) => {
    if (searchTerm && searchTerm.length >= 2) {
      const filtered = inventoryList.filter((item) => {
        const sku = item.itemIdentifier.sku.toLowerCase();
        const description = item.description.toLowerCase();
        return (
          sku.includes(searchTerm.toLowerCase()) ||
          description.includes(searchTerm.toLowerCase())
        );
      });
     
      setFilteredList(filtered);
    } else {
      console.log("setting filtered list to inventory list", inventoryList);
      setFilteredList(inventoryList); // Reset to original results if less than 2 characters or empty
    }
  };

  const updateInventory = (newInventory) => {
    setInventory(newInventory);
  };

  const updateOrders = (newOrders) => {
    setOrders(newOrders);
  };

  const updateCustomerId = (newCustomerId) => {
    setCustomerId(newCustomerId);
  };

  const login = async (email, password) => {
    setLoginError(false);
    console.log("trying login ", `${rootUrl}/api/users/login`);

    /*
    {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: headers,
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url

    body: JSON.stringify(data), // body data type must match "Content-Type" header
  }*/
    try {
      console.log("trying login ", `${rootUrl}/api/users/login`);
      const response = await fetch(`${rootUrl}/api/users/login`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        // redirect: "follow", // manual, *follow, error
        // referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }), // send the login info
      });

      if (!response.ok || !response.success) {
        const message = response.message || "Login failed!";
        setLoginError(message);
      }

      const { token,  customerIds, cbId } = await response.json(); // Destructure the response
      if (token) {
        console.log("token ", token, " email ", email, " customerIds ", customerIds, " cbId ", cbId);
        // Save the JWT token and user info in the state
        setUser({ token, email, customerIds, cbId });
        // Optionally, save the token in localStorage or a cookie for persistence
        localStorage.setItem("authToken", token);
        localStorage.setItem("email", email);
        localStorage.setItem("customerIds", customerIds);
        console.log('setting user ', { token, email,customerIds, cbId });
        localStorage.setItem("cbUser", JSON.stringify({ token, email,customerIds: customerIds, cbId: cbId }));
      }
    } catch (error) {
      console.error("An error occurred during login:", error);
      // Optionally, you could do more with error handling, e.g., set an 'error' state
    }
  };

  const logout = () => {
    // Remove the token from the local storage (or cookies, if you're using them)
    localStorage.removeItem("authToken");

    // Clear the user data from the state/context
    setUser(null);

    // Redirect the user to the login page or wherever appropriate
    // You can use your routing logic here, for instance, if you're using 'react-router-dom', you can use 'navigate' from 'useNavigate' hook for redirection.
  };

  const _addItemToCart = (item, user, email) => {
    const cartItem = {
      description: item.description,
      sku: item.itemIdentifier.sku,
      receiveItemId: item.receiveItemId,
      referenceNum: item.referenceNum,
      // receiverId: item.receiverId,
      userEmail: user && user.email ? user.email : email,
      dateAdded: new Date().toISOString(),
    };

    setCartItems((currentItems) => [...currentItems, cartItem]);
  
  };

  //HIT the DB to sync the cart
  const _updateDBCart = async (cartItem) => {
    console.log('url ',`${rootUrl}/api/customer/cart`)
    fetchWrapper.post(`${rootUrl}/api/customer/cart`, cartItem); 
  };

  const handleRemoveItemFromCart = (sku, receiverId) => {
    setCartItems((currentItems) =>
      currentItems.filter(
        (item) => item.sku !== sku || item.receiverId !== receiverId
      )
    );
  };

  const handleAddToCart = async (item) => {
    let email = "";
    if (!user) {
      //check local storage
      const token = localStorage.getItem("authToken");
      const email = localStorage.getItem("email");
      if (token) {
        setUser({ token, email });
      }
    }
    _addItemToCart(item, user, email);

    // Call your API endpoint to add to the cart
    // Update your local state to reflect the new cart count
    setCartCount(cartCount + 1);

      //update DB cart
      _updateDBCart(item);
  };

  // ... other state initializations

  // Effects to save state changes to local storage
  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(() => {
    localStorage.setItem("cartCount", JSON.stringify(cartCount));
  }, [cartCount]);
  useEffect(() => {
    localStorage.setItem("inventory", JSON.stringify(inventory));
  }, [inventory]);

  useEffect(() => {
    localStorage.setItem("orders", JSON.stringify(orders));
  }, [orders]);

  useEffect(() => {
    localStorage.setItem("customerId", JSON.stringify(customerId));
  }, [customerId]);

  useEffect(() => {
    localStorage.setItem("inventoryList", JSON.stringify(inventoryList));
  }, [inventoryList]);

  useEffect(() => {
    localStorage.setItem("filteredList", JSON.stringify(filteredList));
  }, [filteredList]);

  useEffect(() => {
    localStorage.setItem("loginError", JSON.stringify(loginError));
  }, [loginError]);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  useEffect(()=>{
    
    const inventoryMinusWhatIsInTheCart = inventoryList ? inventoryList.filter(itemOne => 
      !cartItems.some(itemTwo => 
          itemOne.itemIdentifier.sku === itemTwo.sku
      )): [];

      setFilteredList(inventoryMinusWhatIsInTheCart);
  },[cartItems])
  const contextValue = {
    cartCount,
    cartItems,
    customerInfo,
    inventory,
    inventoryList,
    filteredList,
    loginError,
    orders,
    customerId,
    user, //out belcastro user {token: "token"}

    handleAddToCart,
    handleRemoveItemFromCart,
    
    getInventory,
    filterInventory, //for searching through the inventory
    login,
    logout,
    updateInventory,
    updateOrders,
    updateCustomerId,
    setLoginError,
    setCartItems,
    setCustomerInfo
  };

  return (
    <InventoryContext.Provider value={contextValue}>
      {children}
    </InventoryContext.Provider>
  );
}
