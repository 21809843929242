import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './styles.css'; // Path to your custom CSS file

const FullWidthBanner = ({img, title}) => {
  return (
    <Container fluid className="p-0 banner-container">
      <Row noGutters>
        <Col>
          <div className="banner-image">
            <img src={img} alt="Banner" className="img-fluid" />
            <div className="banner-text">
              {title}
              {/* <p>Your banner description text goes here.</p> */}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default FullWidthBanner;
